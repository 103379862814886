<template>
  <div class="edrea_tm_modalbox" :class="open ? 'opened' : ''">
    <div class="box_inner">
      <div class="close">
        <a href="#" @click="close"><i class="icon-cancel"></i></a>
      </div>
      <div class="description_wrap">
        <div class="about_popup_details">
          <div class="left">
            <div class="left_inner">
              <div class="author">
                <div class="avatar_image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/about/1.jpg"></div>
                </div>
                <div class="short">
                  <h3 class="name">
                    {{ aboutData.firstName }}
                    <span class="coloring">{{ aboutData.lastName }}</span>
                  </h3>
                  <h3 class="job">
                    <AnimationText />
                  </h3>
                </div>
              </div>
              <div class="list">
                <ul>
                  <li>
                    <div class="list_inner">
                      <i class="icon-user"></i>
                      <span
                        >{{ aboutData.firstName }}
                        {{ aboutData.lastName }}</span
                      >
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <i class="icon-calendar"></i>
                      <span>{{ aboutData.bithday }}</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <i class="icon-location"></i>
                      <span
                        ><a href="#" class="href_location">{{
                          aboutData.address
                        }}</a></span
                      >
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <i class="icon-phone"></i>
                      <span
                        ><a href="https://api.whatsapp.com/send?1=pt_BR&phone=5513991108959" target="_blank">{{ aboutData.phn }}</a></span
                      >
                    </div>
                  </li>
                </ul>
              </div>
              <div class="edrea_tm_button full">
                <a href="https://api.whatsapp.com/send?1=pt_BR&phone=5513991108959" target="_blank">Entre em contato</a>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="right_inner">
              <div class="biography">
                <div class="about_title">
                  <h3>
                    <span>Sobre <span class="coloring">mim</span></span>
                  </h3>
                </div>
                <div class="text">
                  <p>
                    Olá! sou
                    <span>Vinicius Protti.</span> desenvolvedor web desde 2014, especializado em Python, PHP, HTML, JavaScript e WordPress. 
					Sou apaixonado por criar soluções inovadoras e personalizadas, focando em qualidade e experiência do usuário. 
					Meu objetivo é exceder expectativas e entregar trabalhos que impactam positivamente!
                  </p>
                </div>
              </div>
              <div class="service">
                <div class="about_title">
                  <h3>
                    <span>Meus <span class="coloring">Serviços</span></span>
                  </h3>
                </div>
                <div class="list">
                  <ul>
                    <li v-for="(service, i) in aboutData.serviceLists" :key="i">
                      <i class="icon-right-dir"></i>{{ service }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="prog_skill">
                <div class="about_title">
                  <h3>
                    <span
                      >Habilidades de <span class="coloring">Programação</span></span
                    >
                  </h3>
                </div>
                <div class="oki_progress">
                  <div
                    class="progress_inner"
                    v-for="(skill, i) in aboutData.skills.programming"
                    :key="i"
                  >
                    <span
                      ><span class="label">{{ skill.name }}</span
                      ><span class="number">{{ skill.value }}%</span></span
                    >
                    <div class="background">
                      <div class="bar open">
                        <div
                          class="bar_in"
                          :style="{ width: `${skill.value}%` }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="lang_skill">
                <div class="about_title">
                  <h3>
                    <span>Especialidades em <span class="coloring">Web</span></span>
                  </h3>
                </div>
                <div class="circular_progress_bar">
                  <ul>
                    <li>
                      <div class="list_inner">
                        <div class="myCircle" data-value="1.00"></div>

                        <div class="title"><span>Design Responsivo</span></div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <div class="myCircle" data-value="1.00"></div>

                        <div class="title"><span>Segurança</span></div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <div class="myCircle" data-value="1.00"></div>

                        <div class="title"><span>Otimização da Página</span></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="testimonial">
                <div class="about_title">
                  <h3>
                    <span>Alguns <span class="coloring">Feedback's</span></span>
                  </h3>
                </div>
                <div class="list">
                  <swiper
                    :loop="true"
                    :slidesPerView="1"
                    :spaceBetween="10"
                    :loopAdditionalSlides="1"
                    :autoplay="{
                      delay: 6000,
                    }"
                    :modules="modules"
                    class="owl-carousel"
                  >
                    <swiper-slide>
                      <div class="list_inner">
                        <div class="text">
                          <i class="icon-quote-left"></i>
                          <p>
                            A atenção aos detalhes e a abordagem inovadora da equipe transformaram nossa visão em realidade. 
							A dedicação e o profissionalismo demonstrados superaram nossas expectativas. Altamente recomendado! 
							Obrigado pela dedicação!
                          </p>
                        </div>
                        <div class="details">
                          <div class="image">
                            <div
                              class="main"
                              data-img-url="img/testimonials/1.jpg"
                            ></div>
                          </div>
                          <div class="info">
                            <h3>Ana Souza</h3>
                            <span>Arquiteta</span>
                          </div>
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="list_inner">
                        <div class="text">
                          <i class="icon-quote-left"></i>
                          <p>
                            Não posso agradecer o suficiente pela rapidez e eficiência do suporte prestado. 
							Qualquer dúvida ou ajuste necessário foi tratado de forma imediata e profissional. 
							A equipe é incrível e o serviço é de primeira classe!
                          </p>
                        </div>
                        <div class="details">
                          <div class="image">
                            <div
                              class="main"
                              data-img-url="img/testimonials/2.jpg"
                            ></div>
                          </div>
                          <div class="info">
                            <h3>Carlos Silva</h3>
                            <span>Empresário</span>
                          </div>
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="list_inner">
                        <div class="text">
                          <i class="icon-quote-left"></i>
                          <p>
                            A solução personalizada desenvolvida foi perfeitamente alinhada às nossas necessidades, 
							otimizando nossos processos e elevando nossa produtividade. 
							Estamos extremamente satisfeitos com os resultados e com a expertise da equipe!
                          </p>
                        </div>
                        <div class="details">
                          <div class="image">
                            <div
                              class="main"
                              data-img-url="img/testimonials/3.jpg"
                            ></div>
                          </div>
                          <div class="info">
                            <h3>Fernanda Oliveira</h3>
                            <span>Designer Gráfico</span>
                          </div>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import AnimationText from "../AnimationText.vue";
export default {
  name: `AboutPopup`,
  components: {
    Swiper,
    SwiperSlide,
    AnimationText,
  },
  data() {
    return {
      open: false,
      // data
      aboutData: {
        firstName: "Vinicius",
        lastName: "Protti",
        bithday: "18/02/1999",
        address: "Praia Grande, SP",
        phn: "(13) 99110-8959",
        serviceLists: [
          "Criação de Sites Completos",
			"Montagem de Lojas Virtuais",
			"Sistemas Personalizados em Diversas Linguagens",
			"Manutenção e Atualização de Sites e Wordpress",
			"Ferramentas de Automação",
			"Serviços de Manutenção em Sites e Sistemas",
        ],
        skills: {
          programming: [
            { name: "WordPress", value: "98" },
			{ name: "JavaScript", value: "98" },
			{ name: "Python", value: "96" },
			{ name: "PHP", value: "91" },
			{ name: "HTML", value: "93" },
			{ name: "CSS", value: "95" },
			{ name: "Node.js", value: "91" },
			{ name: "Vue.js", value: "92" },
          ],
          language: [
            { name: "Design Responsivo", value: "1.00" },
            { name: "Segurança", value: "1.00" },
            { name: "Otimização da Página", value: "1.00" },
          ],
        },
        education: [
          {
            year: "2014 - 2016",
            unv: "Oxford Univercity",
            degree: "Master Degree",
          },
          {
            year: "2010 - 2014",
            unv: "Texas Univercity",
            degree: "Bachelor Degree",
          },
          {
            year: "2008 - 2010",
            unv: "Simone College",
            degree: "Associate Degree",
          },
        ],
        working: [
          {
            year: "2018 - running",
            company: "Envato Elements",
            deg: "Exclusive Author",
          },
          {
            year: "2015 - 2018",
            company: "Avo Corporation",
            deg: "Content Manager",
          },
          {
            year: "2012 - 2015",
            company: "FC Barcelona",
            deg: "Football Player",
          },
        ],
        partnersLogos: [
          "img/partners/1.png",
          "img/partners/2.png",
          "img/partners/3.png",
          "img/partners/4.png",
        ],
      },
    };
  },
  methods: {
    close() {
      this.open = false;
    },
  },
  mounted() {
    const aboutPopup = document.getElementById("aboutPopup");
    aboutPopup.addEventListener("click", () => {
      this.open = true;
    });

    const myCircle = document.querySelectorAll(".myCircle");
    myCircle.forEach((circle) => {
      let progress = circle.getAttribute("data-value");
      let span = document.createElement("span");
      span.className = "number";
      const value = `${progress * 100}%`;
      span.innerHTML = value;
      circle.appendChild(span);
      circle.style.setProperty("--progress", value);
      circle.setAttribute("aria-valuenow", value);
    });
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style scoped>
.testimonial {
  overflow: hidden;
}
.owl-carousel.swiper {
  overflow: unset;
}

.myCircle {
  --hue: 220;
  --holesize: 67%;
  --track-bg: hsl(233, 34%, 92%);

  min-width: 110px;
  min-height: 110px;
  display: grid;
  align-items: center;
  justify-items: center;
  place-items: center;
  position: relative;
  margin-bottom: 5px;
}
.myCircle::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  z-index: -1;
  background: conic-gradient(
    var(--main-color) var(--progress, 0%),
    rgba(255, 255, 255, 0.15) var(--progress, 0%) 100%
  );

  mask-image: radial-gradient(
    transparent var(--holesize),
    black calc(var(--holesize) + 1px)
  );
}
</style>
