<template>
  <div class="edrea_tm_section" id="about">
    <div class="section_inner">
      <div class="edrea_tm_about">
        <div class="left">
          <div class="image">
            <img src="img/thumbs/1-1.jpg" alt="" />
            <div class="main" data-img-url="img/about/1.jpg"></div>
          </div>
        </div>
        <div class="right">
          <div class="short">
            <h3 class="name">Vinicius <span class="coloring">Protti</span></h3>
            <h3 class="job">
              <AnimationText />
            </h3>
          </div>
          <div class="text">
            <p>
              Meu nome é <span>Vinicius Protti.</span> 
			  Sou um desenvolvedor web apaixonado e dedicado, especializado em criar soluções inovadoras e personalizadas. 
			  Com experiência desde 2014, domino diversas tecnologias, incluindo Python, PHP, HTML, JavaScript e Wordpress, permitindo-me desenvolver desde sites completos a sistemas complexos e ferramentas de automação.
            </p>
          </div>
          <div class="edrea_tm_button" id="aboutPopup">
            <a href="#">Leia mais</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimationText from "./AnimationText.vue";
export default {
  name: `AboutComponent`,
  data() {
    return {
      open: false,
    };
  },
  components: {
    AnimationText,
  },
};
</script>

<style scoped>
.edrea_tm_modalbox {
  top: -90px;
}
</style>
