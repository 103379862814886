<template>
  <div class="edrea_tm_section" id="contact">
    <div class="section_inner">
      <div class="edrea_tm_contact">
        <div class="edrea_tm_main_title">
          <h3>Entre em <span class="coloring">Contato</span></h3>
        </div>
        <div class="wrapper">
          <div class="left">
            <ul>
              <li>
                <div class="list_inner">
                  <i class="icon-location"></i>
                  <span
                    ><a href="#" class="href_location"
                      >Praia Grande, SP</a
                    ></span
                  >
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <i class="icon-phone"></i>
                  <span><a href="https://api.whatsapp.com/send?1=pt_BR&phone=5513991108959" target="_blank">(13) 99110-8959</a></span>
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <i class="icon-mail-1"></i>
                  <span
                    ><a href="mailto:contato@viniciusprotti.com.br"
                      >contato@viniciusprotti.com.br</a
                    ></span
                  >
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <i class="icon-instagram-1"></i>
                  <span
                    ><a href="https://www.instagram.com/dev.protti/" target="_blank">@dev.protti</a></span
                  >
                </div>
              </li>
            </ul>
          </div>
          <div class="right">
            <div class="fields">
              <form
                action="/"
                method="post"
                class="contact_form"
                id="contact_form"
              >
                <div
                  class="returnmessage"
                  data-success="Your message has been received, We will contact you soon."
                ></div>
                <div class="empty_notice">
                  <span>Please Fill Required Fields</span>
                </div>
                <div class="first">
                  <ul>
                    <li>
                      <div class="list_inner">
                        <input
                          id="name"
                          type="text"
                          placeholder="Nome"
                          autocomplete="off"
                        />
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <input
                          id="email"
                          type="text"
                          placeholder="E-mail"
                          autocomplete="off"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="last">
                  <textarea id="message" placeholder="Menssagem"></textarea>
                </div>
                <div class="edrea_tm_button">
				<a id="send_message" href="#" @click.prevent="openWhatsApp">Enviar Mensagem</a>
				</div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: `ContactComponent`,
  methods: {
    openWhatsApp() {
      let name = document.getElementById('name').value;
      let email = document.getElementById('email').value;
      let message = document.getElementById('message').value;

      // Você pode formatar a mensagem da maneira que preferir
      let formattedMessage = `Nome: ${name}%0AEmail: ${email}%0AMensagem: ${message}`;

      let whatsappURL = `https://wa.me/5513991108959?text=${formattedMessage}`;

      // Abre o WhatsApp em uma nova janela/tab
      window.open(whatsappURL, '_blank');
    }
  }
};
</script>

